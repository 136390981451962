/* About.module.css */

.about-container {
  background-color: hsl(353, 80%, 96%);
  font-family: "EB Garamond", serif;
  height: 100%;
}

.about-meet-jackie {
  font-family: "EB Garamond", serif;
  background-color: white;
}

.about-text-section {
  font-family: "EB Garamond", serif;
  background-color: #fdedef;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.about-left-text {
  text-align: left !important; /* Force left alignment */
  margin: 1rem auto; /* Remove any default or uneven margins */
}
.about-left-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7); /* Adjust transparency here */
  z-index: 0; /* Ensures the overlay is behind the content */
}

.about-left-text {
  position: relative; /* Ensures the text is on top of the overlay */
  z-index: 1; /* Ensures the text is on top of the overlay */
  

  font-size: 20px;
  line-height: 1.75rem;
  text-align: left;
  /* Removed margin settings */
}

.about-right-section {
  background-color: #745B4F;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.about-right-image {
  margin: 0 auto;
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the container */
}

.about-collaborations-section {
  background-color: #EBE8E4;
  display: flex;
  width: 100%;
  background-color: white;
  font-family: "EB Garamond";
}

.about-collaborations-text {
  background-color: #EBE8E4;
  flex: 1;
  font-family: "EB Garamond";
  display: flex;
  justify-content: center;
  align-items: left;
}

.about-collaborations-left-image {
  background-color: #EBE8E4;
  width: 100%;
  height: auto;
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.flashIcon {
  font-size: 2em;
  animation: flash 1s ease-in-out 5; /* Animation runs for 1 second, 5 times with ease-in-out timing */
}

.media-padding {
  padding: 0;
}

/* Responsive styles */
@media (max-width: 2000px) {
  .about-text-section {
    flex-direction: column;
  }

  .about-left-section, .about-right-section {
    width: 100%;
    padding: 1rem;
  }

  .about-meet-jackie, .about-collaborations-text {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .about-left-text, .about-collaborations-text {
    font-size: 1rem;
    padding: 0.5rem;
    text-indent: 1em;
  }

  .about-meet-jackie {
    padding: 0.5rem;
  }

  .about-collaborations-left-image {
    width: 100%;
  }
}
