/* CSS Module (AboutHero.module.css) */
.container {
  width: 100%;
  height: 2000px; /* Set the container height */
  overflow: hidden; /* Hide overflow */
  position: relative; /* Position relative for iframe */
}

.parisPrincessIframe {
  width: 100%;
  height: 1500px; /* Make the iframe fit the container */
  border: none; /* Remove border */
  display: block; /* Ensure it does not cause inline overflow */
}
