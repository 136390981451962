/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body and Container Styles */
body {
  font-family: 'Georgia', serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  background-color: #f4f4f4;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem 20px;
  overflow-x: hidden; /* Ensures content stays within the screen */
}

/* Card Styles */
.card {
  background-color: white;
  color: #000000;
  margin: 1rem auto;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Ensures it doesn’t exceed the viewport on larger screens */
}

/* Blog Content Styles */
.blog-content {
  text-align: left;
  line-height: 1.5;
  max-width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  overflow-wrap: break-word; /* Ensures text doesn't overflow */
}

/* Paragraph Styles */
.paragraph {
  font-size: 18px;
  max-width: 100%;
  margin: 1rem auto;
  line-height: 1.6;
  padding: 0 10px; /* Adds padding to avoid text touching the edges */
}

/* Image Styles */
.image {
  display: block;
  margin: 1rem auto;
  max-width: 100%; /* Ensures images are fully responsive */
  height: auto;
  border-radius: 8px;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .container {
    padding: 1rem 1.5rem;
  }
  .card {
    max-width: 100%; /* Allows card to adapt to screen size */
  }
}

@media screen and (max-width: 800px) {
  .card {
    padding: 1rem 2.5rem;
  }
  .paragraph {
    max-width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding: 1rem 1rem; /* Adds padding to avoid text touching the screen edges */
  }
  .card {
    padding: 1rem;
  }
  .paragraph {
    max-width: 100%;
    font-size: 16px;
    padding: 0 5px; /* Ensure padding to avoid text touching edges */
  }
  .image {
    margin: 0.5rem auto;
  }
}