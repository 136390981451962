/* AdSenseAd.module.css */

.adContainer {
    text-align: center;
    margin: 0 auto ;
    background-color: #ffffff;
  }
  
  .adBlock {
    margin: 1rem auto;

    display: block;
  }