.beautySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'GFS Didot';
    margin: 0 ;
  
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    transform: scale(0.9); /* Scale down the component */
    transform-origin: top center; /* Ensure scaling is centered */
  }
  
  .sectionTitle {
    font-size: 2.5rem; /* Increased font size */
    margin-bottom: 2rem;
  }
  
  .mainArticle {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-decoration: none;
    color: black;
    margin-bottom: 2rem;
  }
  
  .mainImage {
    width: 100%;
    height: 270px; /* Reduced height for better fit */
    object-fit: cover;
  }
  
  .mainContent {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .mainContent h2 {
    font-size: 1rem; /* Reduced font size */
    margin-bottom: 0.5rem;
    transition: text-decoration 0.3s; /* Smooth transition */
  }
  
 
  .mainContent p {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
  }
  
  .sideArticles {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .sideArticle {
    display: flex;
    font-family: 'GFS Didot';
    text-decoration: none;
    color: black;
    background: white;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .sideImage {
    width: 105px; /* Reduced size */
    height: 105px; /* Reduced size */
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 8px;
  }
  
  .sideContent h3 {
    font-size: 1.25rem; /* Reduced size */
    margin-bottom: 0.5rem;
    transition: text-decoration 0.3s; /* Smooth transition */
  }
  
  .sideContent h3:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .sideContent p {
    font-size: 0.9rem; /* Reduced size */
    font-style: italic;
  }
  
  /* Responsive styles */
  @media (min-width: 768px) {
    .mainArticle {
      flex-direction: row;
      margin-bottom: 2rem;
      align-items: center;
    }
  
    .mainImage {
      width: 50%;
      height: 100%;
    }
  
    .sideArticles {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .sideArticle {
      flex: 1 1 calc(50% - 1rem);
      max-width: calc(50% - 1rem);
    }
  }
  
  @media (max-width: 767px) {
    .mainImage {
      height: auto;
    }
  
    .sideArticles {
      flex-direction: column;
    }
  
    .sideArticle {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  