.quizContainer {
  width: auto;
  max-height: 100%;
  margin: 0 auto;
  font-weight: 400;
  padding: 10px;
  text-align: center;
  background-color: white;
  box-sizing: border-box;
}

.questionSection {
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
}

.questionText {
  font-family: 'Playfair Display', serif;
  font-size: 1.4rem;
  font-weight: 400;
  color: #3a3a3a;
  margin: 0.5rem auto;
  text-align: center;
}

.answerSection {
  display: flex; /* Change from grid to flex for column layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  gap: 1rem;
  margin: 0 auto;
  font-weight: 400;
  width: 400px;
  padding: 5px;
}

.answerButton {
  width: 400px; /* Default size for larger screens */
  height: 250px; /* Default size for larger screens */
  color: white;
  font-size: 1.5rem;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  cursor: pointer;
  margin: 0 auto;

  border: 1px solid #ffffff;
  background-color: transparent;
  border-radius: 10px;
  transition: all 0.3s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.answerButton:hover {
  transform: scale(1.05);
  background-color: rgba(0, 0, 0, 0.6);
}

.resultsSection {
  max-width: 100%;
  margin: 1rem auto;
  font-weight: 400;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 12px;
  box-shadow: 1px 6px 8px rgba(0, 0, 0, 0.15);
}

/* Responsive Design for Medium Screens */
@media (max-width: 768px) {
  .questionText {
    font-size: 1.2rem;
  }

  .answerButton {
    width: 100%; /* Smaller size for medium screens */
    height: 300px;
    font-weight: 400;
    font-size: 2rem;
  }
}

/* Responsive Design for Small Screens/Mobile */
@media (max-width: 480px) {
  .quizContainer {
    width: 100%;
    font-weight: 400;
    padding: 30px;
  }

  .questionText {
    font-size: 1rem;
    padding: 5px;
  }

  .answerSection {
    display: flex;
    font-weight: 400;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    gap: 15px;
  }

  .answerButton {
    width: 100%; /* Adjust width to fit the container */
    max-width: 300px; /* Limit the width for larger screens */
    height: 200px; /* Allow height to adjust based on content */
    font-weight: 400;
    font-size: 0.8rem;
    padding: 10px;
  }
}