
.hero {
  width:100%;
  height:20vh;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 0px;
  display: grid;
  place-items: center;
  text-align: center;
   

}

.textReveal {
  display: inline-block;
  padding: 0;
  opacity: 0;
  animation: fade-in 5s forwards;
  font-family: 'Playfair Display', serif; /* Set font to Arapey only */
  font-size: 22px; /* Responsive font size */
  text-align: center; /* Center-align the text */
  font-weight: 400;
  white-space: pre-wrap; /* Allow wrapping on spaces and break text to fit */
  overflow-wrap: break-word; /* Break long words to fit within the container */
  word-wrap: break-word;
  hyphens: auto; /* Automatically hyphenate text if needed */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .hero {
    height: 100px;
  
  }

  
}

@media (max-width: 400px) {
  .hero {
    height: 100px;


  }
}
