
* {
  box-sizing: border-box;
}

body {
  font-family: 'GFS Didot', serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background: linear-gradient(to bottom, #e8edea 0%, #d2d8d3 85%, #c5c9c8 86%, #d2d8d3 100%);
  margin: 0 auto; /* Ensure the gallery is centered horizontally */
}

#frame {
  display: flex;
  margin: 0 auto; /* Center the frame within the gallery */
  flex-wrap: wrap;
  justify-content: center; /* Center the items within the frame */
  list-style: none;
  padding: 0; /* Reset padding to ensure no extra space */
}

#frame li {
  display: none;
  justify-content: center;
  align-items: center;
}

#frame li.active {
  display: block;
}

#frame li figure {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#frame li figure img {
  max-block-size: 325px;
  border: 25px solid transparent;
 
  border-image: url('https://assets.codepen.io/11614/frame2.png') 30 round;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.25);
}

#frame li figure figcaption {
  max-block-size: 100px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  background-color: #fdedef;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  font-size: 10px;
  color: black;
  z-index: 10;
  box-sizing: border-box;
  width: 30%; /* Adjust width for better mobile viewing */
  border: 0.1px solid #745B4F;
}

#bench-container {
  position: relative;
  margin-top: -5rem;
}

#bench {
  max-width: 20rem;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

#nav {
  display: flex;
  justify-content: center;
}

#nav .prev, #nav .next {
  background: none;
  border: none;
  cursor: pointer;
  color: #745B4F;
  font-size: 50px;
}

#nav .prev:hover, #nav .next:hover {
  color: #fdedef;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  #frame li figure img {
    max-width: 225px;
    max-height: 250px;
  }

  #bench {
    max-width: 40%;
  }

  #nav .prev, #nav .next {
    font-size: 2em;
    margin: 1rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  #gallery {
    height: 90vh; /* Adjust height for better mobile viewing */
  }

  #frame {
    padding: 0;
    margin-top: -0.1rem;
    margin-bottom: 0.5rem;
  }

  #frame li {
    margin: 1rem;
  }

  #frame li figure img {
    max-width: 200px;
    max-height: 200px;
  }

  #frame li figure figcaption {
    font-size: 10px; /* Adjust font size for smaller screens */
    width: 80%; /* Adjust width for better mobile viewing */
    left: 50%; /* Center the caption box */
    bottom: -20px; /* Adjust position for smaller screens */
    transform: translateX(-50%);
  }

  #bench-container {
    margin-top: -5rem; /* Adjust bench position for better mobile viewing */
  }

  #bench {
    max-width: 80vw; /* Increase bench size for smaller screens */
  }

  #nav .prev, #nav .next {
    font-size: 24px; /* Decrease font size for navigation buttons on mobile */
  }
}
