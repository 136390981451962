.video-overlay-container {
    justify-content: center;

    position: relative;
    width: 100%; /* Ensure it takes full width of the container */
  }
  
  .subscribe-button-overlay {
    justify-content: center;
    top: 10%;  /* Adjust these values based on your design needs */
    right: 10%;
    z-index: 10; /* Ensures the button stays on top of the video */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .subscribe-button-overlay {
        justify-content: center;

      top: 20px; /* Fixed distance from top for smaller screens */
      right: 20px;
    }
  }
  