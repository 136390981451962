/* General styles for the component */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
  gap: 20px; /* Space between grid items */
  padding: 20px;
  justify-items: center;
}

.gridItem {
  position: relative;
  text-align: center;
  width: 100%;
}

.gridItem a {
  text-decoration: none;
  color: black;
  display: block;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: block;
}

.title {
  margin-top: 1rem;
  font-family: 'GFS Didot', serif;
  font-size: 1.25rem;
  color: black;
}

.gridItem:hover .image {
  transform: scale(1.0);
  opacity: 0.25;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: 'transparent';
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.gridItem:hover .overlay {
  opacity: 1;
}

.readButton {
  font-family: 'Arapey, serif';
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px 20px;
  border: 2px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.readButton:hover {
  background-color: white;
  color: black;
}

.viewAllLink {
  display: inline-flex;
  align-items: center;
  font-family: 'GFS Didot, serif';
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
}

/* Jiggle animation */
@keyframes jiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-3deg); }
  50% { transform: rotate(3deg); }
  75% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
}

.jiggle {
  animation: jiggle 0.5s ease-in-out;
}

@media (max-width: 1024px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}