.body {
    font-family: 'Playfair Display', serif;
    font-weight: 400; 
    background-color: #fdedef;
    color: #745B4F;
    line-height: 1.6;
    margin: 2rem;
    padding: 2rem;
}

@media (min-width: 768px) {
    .body {
        margin: 4rem;
    }
}

@media (min-width: 1024px) {
    .body {
        margin: 6rem;
    }
}

@media (min-width: 1440px) {
    .body {
        margin: 10rem;
    }
}

.heading {
    color: #101010;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

.mainHeading {
    font-size: 24px;
    margin-bottom: 1rem;
}

.subHeading {
    font-size: 22px;
    margin-top: 1rem;
    margin-bottom: 0.5em;
}

.paragraph {
    margin-bottom: 1em;
}
