
.searchBannerNavContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdedef;
    margin: 5px auto ;
    font-family: 'Playfair Display', sans-serif;
    text-transform: lowercase;
    font-weight: 400;
    width: 100%;
    box-sizing: border-box;
}

.searchWrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchInput {
    width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    font-family: 'Playfair Display', serif;
    border: 1px solid #ffffff ;
    border-radius: 50px;
    color: #745B4F;

    outline: none;
    transition: border-color 0.3s ease;
    background-color: #ffffff;
    color: #745B4F;
    box-sizing: border-box;
}

.searchInput:focus {
    border-color: #fdedef;
}

.searchResults {
    position: absolute;
    top: calc(100% + 1rem); /* Places dropdown right below the input field */
    min-width: 300px;
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
    max-width: 350px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    border-radius: 10px;
    overflow: hidden;
    max-height: 700px;
    overflow-y: auto;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    color: #745B4F;

}

.searchResultItem {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 1rem auto;
    border-bottom: 1px solid #fdedef;
    transition: background-color 0.2s ease;
    cursor: pointer;
    color: #ffffff;
}



.searchResultItem:last-child {
    border-bottom: none;
}

.searchResultImage {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 0.75rem;
    border-radius: 4px;
}

.searchResultTitle {
    font-size: 0.9rem;
    font-weight: 400;
    color: #745B4F;
}

.searchResultCategory {
    font-size: 0.8rem;
    color: #745B4F;
}

.searchCallLink {
    color: #745B4F;
    text-decoration: underline;
    font-size: 1.5rem;
    cursor: pointer;
}

.searchCallLink:hover {
    color: #745B4F;

}