
.body {
  box-sizing: border-box;
}

.card {
  width: 100%;
  max-width: 800px;
  background-color: white;
  color: black;
  font-family: 'Playfair Display', serif;
  text-align: left;
  font-weight: 400;
  margin: 1rem auto; /* Center the card */
  padding: 1rem;
  border-radius: 8px;
}

.card img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.blog-content {
  text-align: left;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
  overflow-wrap: break-word;
}

.container {
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.link {
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  color: black;
  text-decoration: none;
}

.titleImage {
  width: 100%;
}

.subscribeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 500px;
}

.signatureImage {
  width: 100%;
}

.affiliateLink {
  text-align: right;
  font-size: 0.75rem;
  font-family: 'GFS Didot', serif;
  margin: 2px 0;
  max-width: 100%;
  margin-left: 0;
}

@media screen and (max-width: 1200px) {
  .container,
  .card,
  .blog-content {
    margin: 0;
    padding: 0.75rem;
  }
}

@media screen and (max-width: 1000px) {
  .container,
  .card,
  .blog-content {
    padding: 0.75rem;
  }

  .card img {
    margin-bottom: 1rem;
    border-radius: 8px;
  }

  .image {
    width: 55%;
  }
}

@media screen and (max-width: 800px) {
  .container,
  .card,
  .blog-content {
    padding: 0.5rem;
  }

  .card img {
    margin-bottom: 1rem;
    border-radius: 8px;
  }

  .image {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .container,
  .card,
  .blog-content {
    padding: 0.5rem;
  }

  .card img {
    margin-bottom: 0.5rem;
    border-radius: 8px;
  }

  .image {
    width: 95%;
  }

  .iframeStyle {
    height: 300px;
  }
}

@media screen and (max-width: 400px) {
  .container,
  .card,
  .blog-content {
    padding: 0.5rem;
  }

  .card img {
    margin-bottom: 0.5rem;
    border-radius: 8px;
  }

  .image {
    width: 100%;
  }

  .iframeStyle {
    height: 250px;
  }
}
