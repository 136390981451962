.carouselImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carouselImage {
    max-width: 500px;
    height: auto; /* This maintains the aspect ratio */
}

.carousel .control-dots {
    display: none; /* Hide the default dots for a cleaner look */
}

.arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
}

.arrowPrev {
    left: 10px;
}

.arrowNext {
    right: 10px;
}

.arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.arrow:focus {
    outline: none;
}
