.quiz-container {
    max-width: 1400px;
    margin: auto;
    text-align: center;
    background-color: white;
    border-radius: 50px;
   margin: 0 auto;
    box-sizing: border-box;
  }
  
  .question-section {
    min-height: 20px; /* Ensure consistent height for the entire question section */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .question-text {
    margin: 1rem ;
    font-size: 24px;
    font-family: 'Playfair Display', serif;
  
    font-weight: 400;
    color: #745b4f;
    min-height: 1rem; /* Set a min-height based on the longest question */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; /* Ensure text stays centered in the question area */
  }
  
  .answer-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    
  }
  
  .answer-button {
    color: white;
    font-size: 26px;
    cursor: pointer;
    border: 0.25px solid #fdedef;
    background-color: transparent;
    border-radius: 10px;
    transition: all 0.5s ease;
    background-size: cover;
    background-position: center;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    background-repeat: no-repeat;
    text-shadow: 2px 8px 8px rgba(0.9, 0, 0, 0.5);
    box-shadow: 0 8px 12px rgba(0.9, 0, 0, 0.25);
    padding: 1rem;
    height: 14vh;
    min-height: 14vh;
  }
  
  .answer-button:hover {
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .results-section {
    max-width: 50%;
    margin: auto;
    text-align: center;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 1 6px 8px rgba(0, 0, 0, 0.9);
  }
  
  @media (max-width: 800px) {
    .quiz-container {
      max-width: calc(100% - 20px);
      margin: 1rem auto;
   
    }
  
    .answer-section {
      grid-template-columns: 1fr;
    }
  
    .answer-button {
      height: calc(50vh / 7);
      padding: 1rem;
    }
  }
  
  @media (min-width: 801px) {
    .answer-button {
      height: 5vh;
    }
  }
  
  @media (min-width: 1200px) {
    .quiz-container {
      max-width: 85%;
    }
  }