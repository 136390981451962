
.container {
  margin: 1em;
  display: flex;
  color: #745B4F;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Playfair Display', sans-serif;
  border: 10px solid #FDEDEF;
  background-color: #FDEDEF;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  box-sizing: border-box; /* This line ensures padding and border are included in the element's total width/height */
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
}

.column {
  padding:1rem;
  margin: 20px;
  font-family: 'Playfair Display', sans-serif;
  flex: 1;
  color: #745B4F;
  position: relative;
  overflow: hidden;
}

.form {
  margin: 10px;
  gap: 0.5rem;
  display: flex;
  font-family: 'Playfair Display', sans-serif;
  flex-direction: column;
}

.input, .textarea {
  padding: 1rem;
  background-color: white;
  font-family: 'Playfair Display', sans-serif;
  font-size: 1rem;
  border: 1px solid #ccc;
  width: 100%; /* Ensure inputs take full width of form */
}

.button {
  font-size: 22px;
  padding: 1rem;
  font-family: 'Playfair Display', sans-serif;
  border: none;
  color: #fcfaf4;
  cursor: pointer;
  width: 100%; /* Make the button take full width of form */
  background-color: #745B4F; /* Explicitly set the background color */
  -webkit-appearance: none; /* Remove default styling on iOS */
  appearance: none; /* Remove default styling */
}

.button:focus {
  outline: none; /* Remove the default focus outline */
}

.button:hover {
  background-color: #e0e0e0; /* Change background on hover */
}

/* Image overlay styles */
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Right aligned text */
.rightAlign {
  text-align: right;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    margin: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1rem;
    max-height: 100vh;
    font-family: 'Playfair Display', sans-;
    border: 10px solid white;
    background-color: #FDEDEF;
    background-size: cover;
    background-position: center center;
    background-repeat: repeat;
    box-sizing: border-box; /* This line ensures padding and border are included in the element's total width/height */
    flex-wrap: wrap; /* Enable wrapping for smaller screens */
  }

  .form {
    padding: 1rem;
  }

  .input, .textarea {
    padding: 1rem; /* Slightly reduce padding */
  }

  .button {
    padding: 1rem;
  }

  .column {
    position: relative; /* Reset the position for smaller screens */
    overflow: visible; /* Allow overflow for smaller screens */
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure the image overlay stays on top */
  }
}

@media (max-width: 480px) {
  .container {
    font-size: 0.75rem; /* Further reduce font size for very small devices */
  }

  .column {
    padding: 0.1rem;
  }

  .form {
    padding: 0.1rem;
  }
}
