body {
  font-family: "GFS Didot", serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 10px;
}

* {
  box-sizing: border-box;
}

.itemContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem auto;
  flex-wrap: wrap; /* Enable wrapping */
}

.items {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  margin: 1em;
}

.item {
  display: inline-block;
  position: relative;
  left: 0px;
  margin: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc(33% - 2em);
}

.itemImg {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5); /* Add box shadow */
  display: block;
  object-fit: cover;
}

.item::before {
  display: block;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: #fff;
  opacity: 0;
  border-radius: 7.5px; /* Match border radius of image */
}

.item:hover::before {
  opacity: 0.6;
}

.item:hover .itemLink {
  opacity: 1;
  visibility: visible;
}

.itemLink {
  text-decoration: none;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border: 1px solid white;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  border-radius: 0px; /* Match border radius of image */
}

.itemLink:hover {
  background-color: white;
  color: black;
}

.slider {
  background: transparent;
  border: 2px solid white;
  color: black;
  padding: 10px;
}

.slider:hover {
  background-color: black;
  color: white;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .itemContainer {
    flex-direction: column;
  }

  .item {
    flex-basis: calc(100% - 30px); /* Full width for smaller screens */
  }

  .itemLink {
    font-size: 1rem; /* Adjust link text size if needed */
  }
}
