.hero {
  width: 100%;
  height: auto;
  background-color: #fdedef;
  display: flex;
  justify-content: center;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  align-items: center;
  text-align: center;
  padding: 2rem 0; /* Add some padding for a better look */
}

.textReveal {
  display: inline-block;
  opacity: 0;
  animation: fade-in 1s forwards;
  font-family: 'Playfair Display', serif; /* Font to Arapey */
  font-size: 40px; /* Adjusted font size for readability */
  text-align: center; /* Center-align the text */
  font-weight: 100;
  white-space: pre-wrap; /* Allow wrapping on spaces and break text to fit */
  overflow-wrap: break-word; /* Break long words to fit within the container */
  word-wrap: break-word;
  hyphens: auto; /* Automatically hyphenate text if needed */
  padding: 1rem;
  max-width: 100%; /* Restrict text width for better readability */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .textReveal {
    font-size: 1.75rem; /* Smaller font size for tablets */
    max-width: 90%; /* Slightly wider for smaller screens */
  }
}

@media (max-width: 480px) {
  .textReveal {
    font-size: 1.5rem; /* Smaller font size for mobile */
    padding: 1rem 0.5rem; /* Adjust padding for small screens */
  }
}