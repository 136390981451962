.mediaKitSlider {
    max-width: 600px;
    padding: 1rem;
  
    margin: 0 auto;
  }
  
  .sliderImage {
    justify-content: center;
    width: 100%;
    height: auto;
  }
  
  .slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    z-index: 1;
    background-color: transparent; /* Set background to transparent */
    border: none; /* Remove border if any */
  }
  
  .slick-prev {
    left: -50px; /* Adjust this value to move the arrow farther left */
  }
  
  .slick-next {
    right: -50px; /* Adjust this value to move the arrow farther right */
  }
  
  .slick-prev::before, .slick-next::before {
    font-size: 50px;
    color: black; /* Adjust arrow color */
    content: ''; /* Remove default content */
  }
  