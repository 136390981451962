.quiz2-answer-section {
  display: flex;
  flex-direction: column; /* Stack vertically */
  gap: 15px; /* Spacing between buttons */
  align-items: center; /* Center align the buttons */
  border-radius: 50px;

}

.quiz2-answer-button {
  width: 400px; /* Limit the width */
  height: 100px; /* Set a fixed height for better fit */
  border: 2px solid #fdedef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border-radius: 25px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-size: 18px; /* Slightly smaller text for better fit */
  font-weight: 400;
  display: flex;
  justify-content: center; /* Center text inside button */
  align-items: center; /* Center text vertically */
  transition: transform 0.3s ease; /* Removed box-shadow transition */
}

.quiz2-answer-button:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);

}

.quiz2-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 25px;
  border: none; /* Remove any potential borders */
}

.quiz2-question-text {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  color: #745b4f;
  margin-bottom: 1rem;
}