
.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;  
  margin: 0 auto;
}

.input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.error {
  color: red;
  font-size: 0.875rem;
}

@media (max-width: 600px) {
  .form {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .input, .button {
    width: 100%;
  }
}