.quiz6-container {
    display: flex;
    padding-top: 2rem;
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    max-width: 100%; /* Adjust to screen size */
    margin: 1rem auto; /* Center horizontally */
    height: 80vh; /* Full viewport height */
    text-align: center;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01); /* Optional shadow */
  }
  
  .quiz6-question {
    margin: 1rem auto;
    font-size: 24px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    color: #745b4f;
    text-align: center;
  }
  
  .quiz6-answer-section {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px; /* Add space between buttons */
    align-items: center; /* Center align buttons */
  }
  
  .quiz6-answer-button {
    width: 400px; /* Full width on smaller screens */
    max-width: 400px; /* Limit max width */
    height: 150px; /* Adjust height proportionally */
    border: 1px solid #ffffff;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    color: #ffffff;
    font-family: "Playfair Display", serif;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .quiz6-answer-button:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  }
  
  .quiz6-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
